import { maxImageHeight, maxImageWidth } from '../constants/image';

interface ResizeImageResponse {
  width: number;
  height: number;
  imageUrl: string;
}

export const resizeImage = (
  file: File,
  maxWidth = maxImageWidth,
  maxHeight = maxImageHeight,
) => {
  const canvas = document.createElement('canvas');
  const img = document.createElement('img');
  const reader = new FileReader();
  let resolveFn: (resolveResponse: ResizeImageResponse) => void;

  reader.onload = ({ target }) => (img.src = target?.result as string);

  img.onload = async () => {
    let width = img.width;
    let height = img.height;

    if (width > height && width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    } else if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    canvas.width = width;
    canvas.height = height;

    const response = {
      width,
      height,
      imageUrl: img.currentSrc,
    };

    resolveFn(response);
  };

  reader.readAsDataURL(file);

  return new Promise<ResizeImageResponse>((resolve) => {
    resolveFn = resolve;
  });
};
